<script lang="ts" setup>
import type { FeedbackMessageType } from "~/models/forms";

defineProps<{ message: string; messageType: FeedbackMessageType }>();

const commonUiOptions = { strategy: "override", rounded: "" };
</script>

<template>
  <div>
    <UAlert
      v-if="message.length > 0 && messageType === 'success'" variant="subtle"
      icon="i-heroicons-check" :description="message"
      :ui="{
        ...commonUiOptions,
        variant: { subtle: 'bg-green-100 text-green-800 font-bold' },
      }"
    />

    <UAlert
      v-if="message.length > 0 && messageType === 'warning'" variant="subtle"
      icon="i-heroicons-exclamation-circle" :description="message"
      :ui="{
        ...commonUiOptions,
        variant: { subtle: 'bg-orange-100 text-orange-800 font-bold' },
      }"
    />

    <UAlert
      v-if="message.length > 0 && messageType === 'error'" variant="subtle"
      icon="i-heroicons-exclamation-triangle" :description="message"
      :ui="{
        ...commonUiOptions,
        variant: { subtle: 'bg-red-100 text-red-800 font-bold' },
      }"
    />
  </div>
</template>

<style scoped>

</style>
